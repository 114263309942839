dc-accordion {
	&:not(.accordion-group-item) {
		.accordion-container {
			@apply rounded-xl border border-solid;

			.dc-accordion-header {
				@apply px-12;
				.accordion-arrow {
					top: 18px;
				}

				&.open {
					.accordion-arrow {
						top: 22px;
					}
				}
			}
		}
	}

	.dc-accordion-header {
		.accordion-arrow {
			right: 27px;
			transform: translateY(25%) rotate(225deg);
		}

		& + .accordion-content {
			@apply overflow-hidden transition-all duration-300;
		}

		&.open {
			.accordion-arrow {
				transform: translateY(50%) rotate(45deg);
			}

			& + .accordion-content {
				@apply px-4;
			}
		}
	}

	&.accordion-group-item {
		.accordion-container {
			@apply border-b border-x border-solid border-gray-800;
		}
		.dc-accordion-header {
			@apply text-base hover:bg-gray-100 py-3 px-6;
			.accordion-arrow {
				top: 8px;
			}
			&.open {
				@apply bg-gray-100;
				.accordion-arrow {
					top: 13px;
				}
			}
		}

		&:first-child {
			.accordion-container {
				@apply border-t border-solid rounded-t-xl;
			}

			.dc-accordion-header {
				@apply rounded-t-xl;
			}
		}
		&:last-child {
			.accordion-container {
				@apply rounded-b-xl;
			}

			.dc-accordion-header {
				&.open {
					@apply rounded-b-none;
					&:hover {
						@apply rounded-b-none;
					}
				}
				&:hover {
					@apply rounded-b-xl;
				}
			}
		}
	}
}
