.grid-header-cell {
	@apply border px-4 py-2 text-left text-gray-900 text-sm;
}

.grid-cell {
	@apply border px-4 py-2 text-sm;
}

.table-full {
	width: calc(100% - 1rem);
}
