dc-notifier {
	.dc-notifier {
		@apply fixed block text-right;
		top: 2em;
		right: 2em;
		perspective: 500px;
		z-index: 1000000;
		max-width: 85%;

		li {
			@apply block mb-4 max-w-lg;

			&.animate-appear-and-disappear {
				animation-name: appear-and-disappear;
				animation-duration: 6s;
				animation-fill-mode: forwards;
				animation-timing-function: ease-in-out;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				transform-style: preserve-3d;
				transition: all 0.25s ease-in-out;
			}

			&.animate-appear {
				animation-name: appear;
				animation-duration: 0.8s;
				animation-fill-mode: forwards;
				animation-timing-function: ease-in-out;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				transform-style: preserve-3d;
				transition: all 0.25s ease-in-out;
			}

			> span {
				@apply inline-block rounded-lg p-4 shadow-notification text-left;

				.notification-line {
					@apply block;

					&:not(:last-child) {
						@apply mb-3;
					}
				}
			}

			button {
				@apply rounded-full text-xl text-white bg-gray-900 flex items-center justify-center cursor-pointer absolute;
				width: 2em;
				height: 2em;
				top: -1em;
				right: -1em;

				&:hover {
					@apply bg-brand-secondary;
				}
			}
		}
	}
}

@keyframes appear {
	0% {
		max-height: 0;
		transform: rotateX(-180deg) scale(1.5);
		opacity: 0;
	}
	50% {
		max-height: 150px;
		transform: rotateX(0) scale(1);
		opacity: 1;
	}
	75% {
		transform: rotateX(0) scale(1.1);
	}
	100% {
		transform: rotateX(0) scale(1);
	}
}

@keyframes appear-and-disappear {
	0% {
		max-height: 0;
		transform: rotateX(-180deg) scale(1.5);
		opacity: 0;
	}
	10% {
		max-height: 150px;
		transform: rotateX(0) scale(1);
		opacity: 1;
	}
	15% {
		transform: rotateX(0) scale(1.1);
	}
	20% {
		transform: rotateX(0) scale(1);
	}
	90% {
		max-height: 150px;
		transform: rotateX(0);
		opacity: 1;
	}
	100% {
		max-height: 0;
		transform: rotateX(180deg);
		opacity: 0;
	}
}
