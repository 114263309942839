@tailwind base;

@tailwind components;

@tailwind utilities;

@import "variables";

@import "form";
@import "global";
@import "graphics";
@import "grid";
@import "header";
