dc-modal {
	.dc-modal {
		.modal-content {
			div {
				div {
					div {
						.dc-select {
							.select-items {
								max-height: 200px;
								overflow: auto;
								&.item-title {
									overflow-x: hidden;
								}
							}
						}
					}
				}
			}
		}
	}
}
