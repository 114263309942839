@import "../../../../assets/css/variables";

dc-tabs {
	.tab-navigation {
		@apply flex;

		li {
			@apply -mb-px py-2 text-brand-primary-light overflow-hidden relative text-ellipsis;

			&:after {
				@apply bg-brand-secondary left-0 bottom-0 absolute w-full content-[""] scale-x-0 h-0.5 transition duration-150 ease-in-out;
			}

			&:hover {
				@apply cursor-pointer  text-brand-primary-dark;

				&:after {
					@apply scale-x-100;
				}
			}

			&.active {
				@apply text-brand-primary-dark cursor-default font-bold;
				&:after {
					@apply scale-x-100;
				}
			}
		}
	}

	.tab-panel {
		@apply bg-white pb-6 hidden;

		&.active {
			@apply block;
		}
	}
}
