ww-language-select {
	@apply h-full;
}

.ww-language-select-container {
	@apply relative z-10 bg-white h-full flex items-center;
}

.ww-language-select-dropdown-opener {
	@apply text-gray-800 font-semibold cursor-pointer px-4 flex items-center select-none;

	img {
		@apply mr-2;
		width: 20px;
		height: 20px;
	}

	span {
		@apply mr-2;
	}

	.language-select-arrow {
		@apply transform rotate-90 common-transition flex items-center justify-center;
		width: 2rem;
		height: 2rem;

		&.open {
			@apply -rotate-90;
		}
	}
}

.ww-language-select-dropdown {
	@apply absolute w-full text-gray-800 font-semibold bg-white common-transition overflow-hidden;
	top: 100%;
	left: 0;
	max-height: 0;

	&.open {
		@apply shadow-lg;
		max-height: 240px;
	}
}

.ww-language-select-dropdown-item {
	@apply text-gray-800 font-semibold cursor-pointer flex items-center px-4 py-3;

	&:first-child {
		@apply pt-0;
	}

	img {
		@apply mr-2;
		width: 20px;
		height: 20px;
	}
}

dc-modal {
	.dc-modal {
		&.language-alert {
			max-width: 450px;
			margin-top: 150px;
		}
	}
}

.language-alert-modal-btn-container {
	@apply flex flex-col items-center justify-center px-20;

	.btn-primary {
		@apply mb-6 w-full flex justify-center;
		text-align: center;
	}

	.btn-secondary {
		@apply border border-solid border-brand-primary text-brand-primary w-full flex justify-center;
		text-align: center;
	}
}

// Media-queries

// MD
@media screen and (max-width: 1023px) {
	dc-modal {
		.dc-modal {
			&.language-alert {
				@apply mt-0;
				max-width: 400px;
				margin-left: 150px;
			}
		}
	}

	.language-alert-modal-btn-container {
		@apply px-2;
	}
}

// SM
@media screen and (max-width: 767px) {
	dc-modal {
		.dc-modal {
			&.language-alert {
				@apply ml-0;
				max-width: 95%;
			}
		}
	}
}

// XS
@media screen and (max-width: 639px) {
	dc-modal {
		.dc-modal {
			&.language-alert {
				@apply ml-0;
				max-width: 95%;
			}
		}
	}
}
