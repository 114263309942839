dc-table {
	.dc-table {
		td {
			&.actions {
				overflow: visible;

				.dc-dropdown {
					@apply my-1 py-2;

					.select-button {
						@apply my-0 py-0;

						&:disabled {
							@apply cursor-default;
						}
					}
				}

				div.item {
					text-align: left;
				}
			}
		}
	}
}
