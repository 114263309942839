.cesop-period-editor {
	.dc-modal {
		max-width: 95%;
		&.default {
			width: 1200px;
		}
	}
}

.cesop-second-layer-modal {
	.dc-modal {
		max-width: 90%;
		&.default {
			width: 1000px;
		}
	}
	.modal-overlay {
		z-index: 50;
	}
}
