@import "../../../../assets/css/variables";

$color_checkbox_success: $brand-primary;
$color_checkbox_default: $gray-light;
$toggle-bg-color: #fff;
$transition: 0.2s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$width: 2.5rem;
$height: calc($width/2);
$mobile-width: 3.3rem;
$mobile-height: calc($mobile-width/2);
$checkbox-border-size: 2px;
$checkbox-size: 20px;
.switch {
	& + label {
		@apply relative select-none inline-block;
		padding-left: $checkbox-size * 1.5;
	}
	.toggle {
		text-align: center;

		.toggle-control {
			border: 1px solid $gray-light;
			padding-right: $checkbox-size * 1.5;
			transition: $transition;
			width: $mobile-width;
			height: $mobile-height;
			display: block;
			border-radius: $height;
			background-color: rgba(black, 0.06);
			position: relative;
			cursor: pointer;
			&:after {
				transition: $transition;
				content: "";
				width: calc($mobile-width * 10 / 22);
				height: calc($mobile-width * 10 / 22);
				display: block;
				background-color: $toggle-bg-color;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		input {
			opacity: 0;
			&:checked + .toggle-control {
				border-color: $color_checkbox_success;
				background-color: $color_checkbox_success;
				&:after {
					left: calc($mobile-width/2);
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.switch {
		.toggle {
			.toggle-control {
				width: $width;
				height: $height;

				&:after {
					width: calc($width * 10 / 22);
					height: calc($width * 10 / 22);
				}
			}

			input {
				&:checked + .toggle-control {
					&:after {
						left: calc($width/2);
					}
				}
			}
		}
	}
}
