@import "../../../../../ClientApp/assets/css/variables";

dc-pager {
	.dc-pager {
		@apply flex select-none items-center text-sm;

		.dc-pager-items {
			@apply flex select-none;

			div {
				@apply p-2 mx-1 border border-solid border-transparent cursor-pointer rounded-md;

				&.current-page {
					border-color: $brand-primary-dark;
				}

				&:hover {
					@apply text-white cursor-pointer bg-brand-primary-dark rounded-md;

					> svg {
						path {
							@apply fill-white;
						}
					}
				}
			}
		}

		.page-size-selector {
			@apply mx-2 inline-block;
		}
	}
}
