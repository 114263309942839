@import "../_variables";
@import "../../../src/app/web/components/language-select.scss";
.client-hamburger {
	@apply hidden;
}

.client-hamburger-label {
	@apply hidden;
}

.client-menu {
	@apply flex items-center h-full border-b border-solid border-gray-400;
}

.btn-full {
	@apply block w-full text-center px-0 mx-0;
}

.router-mount-point {
	@apply absolute w-full;
	margin-top: 60px;
	padding: 0 2rem 0 2rem;

	@media (min-width: 768px) {
		padding: 0 2rem 0 19.5rem;
	}
}

.link {
	@apply text-sm text-brand-primary;
	&:hover {
		@apply text-brand-primary-light underline cursor-pointer;
	}
}

.text-shadow {
	text-shadow: 0 1px 2px #000;
}

.web-reset-password {
	background-image: url("/images/web_resetpw_1920x1080.jpg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	@media screen and (max-width: 414px) {
		background-image: url("/images/web_resetpw_504x896.jpg");
	}
}

.web-forgot-password {
	background-image: url("/images/web_forgotpw_1920x1080.jpg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	@media screen and (max-width: 414px) {
		background-image: url("/images/web_forgotpw_504x896.jpg");
	}
}

.guides-link:hover {
	svg {
		fill: $brand-primary;
	}
}

.order-services-ioss-selector-container {
	@apply flex mt-4;
	min-width: 400px;
	min-height: 50px;
}

/* MEDIA QUERIES */

// XS
@media screen and (max-width: 639px) {
	$hamburger-height: 2px;
	$hamburger-width: 2rem;

	.client-menu {
		@apply absolute bg-white flex flex-col items-stretch left-0 right-0 overflow-hidden common-transition z-10 h-auto;
		top: 100%;
		max-height: 0;
		> a {
			@apply px-8 py-4 border-b border-solid border-gray-300 w-full border-r-0;
		}
	}
	.ww-language-select-container {
		@apply block;
	}
	.ww-language-select-dropdown-opener {
		@apply px-8 py-4 block;
		img {
			@apply inline-block;
		}
		.language-select-arrow {
			@apply absolute;
			top: 11px;
			right: 1rem;
		}
	}
	.ww-language-select-dropdown {
		@apply relative top-0;
	}
	.ww-language-select-dropdown-item {
		@apply px-8 py-2;
	}

	.client-hamburger-label {
		@apply absolute vertical-center bg-gray-500 common-transition inline-block;
		width: $hamburger-width;
		height: $hamburger-height;
		right: 2rem;
		&:before {
			@apply absolute bg-gray-500 common-transition;
			content: "";
			width: $hamburger-width;
			height: $hamburger-height;
			top: -0.5rem;
			right: 0;
		}
		&:after {
			@apply absolute bg-gray-500 common-transition;
			content: "";
			width: $hamburger-width;
			height: $hamburger-height;
			top: 0.5rem;
			right: 0;
		}
	}
	.client-hamburger {
		@apply opacity-0 inline-block;
		&:checked {
			~ .client-menu {
				max-height: 400px;
			}
			~ .client-hamburger-label {
				@apply bg-white;
				&:before {
					top: 0;
					transform: rotate(45deg);
				}
				&:after {
					top: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}
	.order-services-ioss-selector-container {
		min-width: 0;
	}
}

.remove-icon-container {
	@apply text-brand-primary cursor-pointer flex items-center justify-center w-6 h-6;

	&[disabled] {
		@apply text-gray-400 pointer-events-none;
	}
}
