@import "../../../../assets/css/variables";

$dc-table-margin: 20px 0 !default;
$dc-table-col-padding-h: 10px !default;
$dc-table-col-padding-v: 0px !default;
$dc-table-input-width: 250px !default;
$dc-table-heading-font-weight: bold !default;
$dc-table-font-size: 14px !default;
$dc-table-header-height: 38px !default;
$dc-table-filter-icon-width: 20px !default;
$dc-table-filter-editor-width: 160px !default;

$dc-table-border-color: #e2e2e2 !default;
$dc-table-heading-background-color: #fff !default;
$dc-table-color: #333 !default;
$dc-table-row-bg: #fff !default;
$dc-table-alternate-row-bg: #f5f5f5 !default;
$dc-table-accent-bg: $brand-primary !default;
$dc-table-accent-color: #fff !default;
$dc-table-accent-border-color: $brand-primary !default;
$dc-table-link-color: $brand-primary !default;
$dc-table-modal-border-color: #333 !default;
$dc-table-modal-shadow: $common-shadow !default;

$dc-table-filter-top: 35px !default;
$dc-table-filter-input-padding: 10px 15px;
$dc-table-filter-left: -1px !default;
$dc-table-filter-border-color: #333 !default;
$dc-table-filter-input-font-size: $dc-table-font-size !default;

$dc-table-link-color: $brand-primary !default;
$dc-table-hover-link-color: lighten($brand-primary, 10%) !default;

dc-table {
	.dc-table {
		@apply w-full border;

		thead {
			background: $dc-table-heading-background-color;
		}

		tbody {
			tr {
				background: $dc-table-row-bg;

				&:nth-child(even) {
					background-color: $dc-table-alternate-row-bg;
				}
			}
		}

		th,
		td {
			position: relative;
			border-bottom: 2px solid $dc-table-border-color;
			padding: $dc-table-col-padding-v $dc-table-col-padding-h;
			font-size: $dc-table-font-size;
		}

		th {
			height: $dc-table-header-height;
			padding: $dc-table-col-padding-v $dc-table-col-padding-h;
			border-right: 1px solid $dc-table-border-color;
			text-align: left;
			font-size: $dc-table-font-size;
			color: $text-color;
			user-select: none;
			vertical-align: middle;

			&:last-child {
				border-right: none;
			}

			&.active {
				background-color: white;
			}
		}

		td {
			height: 32px;
			vertical-align: middle;
			border-bottom: 1px solid $dc-table-border-color;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			a {
				cursor: pointer;
				color: $dc-table-link-color;
				&:hover {
					color: $dc-table-hover-link-color;
				}
			}
		}

		&.isHoverable {
			tbody {
				tr {
					&:hover {
						background-color: $dc-table-accent-bg;
						color: $dc-table-accent-color;

						td {
							a {
								color: $dc-table-hover-link-color;
							}
						}
					}
				}
			}
		}
	}
}
