dc-modal {
	.dc-modal {
		@apply fixed shadow-2xl;
		left: 50%;
		transform: translate(-50%, 0%);
		transition: opacity 0.25s ease;
		z-index: 50;

		&.default {
			max-height: 80vh;
			width: 70vw;
			top: 10%;
		}

		.modal-header {
			@apply text-white rounded-t-xl bg-brand-primary-dark p-4 flex justify-between;
		}

		.modal-content {
			@apply relative bg-white rounded-b-xl;

			.modal-overflow-h {
				@apply overflow-y-auto h-auto;
				max-height: 75vh;
			}
			.modal-overflow-w {
				@apply overflow-x-auto w-auto;
			}
		}
	}

	.modal-overlay {
		@apply fixed inset-0 opacity-50 bg-gray-900;
		z-index: 49;
	}
}
