dc-multiswitch {
	@apply mb-3 relative;

	.selector-label {
		@apply px-4 py-2 select-none text-sm w-36 flex justify-center items-center;

		&:first-child {
			@apply rounded-l-xl border-brand-primary;
		}

		&:last-child {
			@apply rounded-r-xl border-brand-primary;
		}
	}

	.selector-label {
		&.selected {
			@apply bg-brand-secondary text-brand-primary font-semibold;
		}

		&.disabled {
			@apply bg-gray-200 border-gray-500 text-gray-500;

			&.selected {
				@apply bg-gray-400 text-gray-600;
			}
		}
	}

	.validation-result {
		@apply text-red-500 text-xs font-bold absolute w-full left-0 bg-white;
		top: 120%;
		line-height: 1.2;
	}
}
