@mixin arrow($color) {
	@apply absolute w-0 h-0 pointer-events-none;
	content: "";
	top: 50%;
	transform: translateY(-50%) translateY(3px);
	transform-origin: top center;
	transition: all 0.2s ease-in-out;
	right: 10px;
	border: 6px solid transparent;
	border-color: $color transparent transparent transparent;
}

dc-select {
	.dc-select {
		@apply relative select-none block w-full;

		&:after {
			@include arrow(#666666);
		}

		&.opened {
			&:after {
				transform: translateY(-50%) translateY(9px) rotate(-180deg);
			}
		}

		.select-input {
			@apply bg-white text-gray-800 border border-solid border-gray-600 cursor-pointer w-full;
			padding: 0.365rem 0.75rem 0.365rem 0.57rem;

			&.readonly {
				@apply bg-gray-200 cursor-default;
			}

			&.invalid {
				@apply bg-red-200;
			}
		}

		.select-items {
			@apply absolute bg-white left-0 overflow-auto;
			min-width: 100%;
			max-height: 200px;
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
			top: 100%;
			z-index: 99;
			text-align: left;

			.item {
				@apply bg-white text-gray-800 py-2 px-3 border border-transparent cursor-pointer select-none whitespace-nowrap;

				&:hover,
				&.keyboard-selected {
					@apply bg-brand-primary text-white;
				}

				&.selected {
					@apply bg-brand-primary-dark text-white;
				}
			}
		}
	}

	&.sm {
		@apply w-12;

		.dc-select {
			.select-input {
				@apply text-sm py-1 px-2;
			}

			&:after {
				right: 3px;
			}
		}
	}

	.validation-result {
		@apply text-red-500 text-xs font-bold absolute w-full left-0 bg-white;
		top: 100%;
		line-height: 1.2;
	}
}
