@mixin arrow($color) {
	@apply absolute w-0 h-0 pointer-events-none;
	content: "";
	top: 50%;
	transform: translateY(-50%) translateY(3px);
	transform-origin: top center;
	transition: all 0.2s ease-in-out;
	right: 10px;
	border: 6px solid transparent;
	border-color: $color transparent transparent transparent;
}

@mixin status-color($color) {
	.select-button {
		@apply ml-2;
		&:before {
			@apply absolute w-2 h-2 rounded-full vertical-center left-0 ml-2 bg-#{$color};
			content: "";
		}
	}
}

dc-dropdown {
	.dc-dropdown {
		@apply bg-brand-primary text-white border relative py-3 pl-4 pr-8 leading-none rounded-md select-none cursor-pointer;

		&:not(.readonly) {
			&:not(.borderless) {
				button {
					&:focus {
						@apply outline-none;
					}

					&:after {
						@include arrow(#ffffff);
					}
				}
			}
		}

		&.opened {
			button {
				&:after {
					transform: translateY(-50%) translateY(9px) rotate(-180deg);
				}
			}
		}

		&.status-like {
			@apply bg-white text-black;

			&:not(.readonly) {
				&:not(.borderless) {
					button {
						&:after {
							@include arrow(#666666);
						}
					}
				}
			}
		}

		&.readonly {
			@apply bg-gray-200 cursor-default;

			button {
				&:after {
					content: "";
				}
			}
		}

		.select-items {
			@apply absolute bg-white overflow-auto right-auto rounded-md min-w-full;
			transform: translateY(3px);
			min-width: 100%;
			max-height: 400px;
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
			top: 100%;
			z-index: 99;
			text-align: left;

			&.left-align {
				@apply left-0;
			}

			&.right-align {
				@apply right-0;
			}

			.item {
				@apply bg-white text-gray-800 py-2 px-3 border border-transparent select-none whitespace-nowrap;

				&:hover,
				&.keyboard-selected {
					@apply bg-brand-primary text-white;
				}

				&.selected {
					@apply bg-brand-primary-dark text-white;
				}
			}
		}

		&.borderless {
			@apply border-none;

			&:not(.readonly) {
				button {
					&:after {
						content: "";
					}
				}
			}

			&.opened {
				.accordion-arrow {
					top: 10px;
					transform: translateY(50%) rotate(45deg);
				}

				button {
					&:after {
						content: "";
					}
				}
			}

			.accordion-arrow {
				top: 8px;
				right: 27px;
				transform: translateY(25%) rotate(225deg);
			}

			.select-items {
				top: 85%;
			}
		}

		&.status-color-red {
			@include status-color(red-500);
		}

		&.status-color-green {
			@include status-color(green-500);
		}

		&.status-color-orange {
			@include status-color(orange-300);
		}

		&.status-color-gray {
			@include status-color(gray-500);
		}

		.select-button {
			&:disabled {
				@apply cursor-default;
			}
		}
	}

	&.sm {
		@apply w-12;

		.dc-dropdown {
			&:after {
				right: 3px;
			}
		}
	}

	.validation-result {
		@apply text-red-500 text-xs font-bold absolute w-full left-0 bg-white;
		top: 100%;
		line-height: 1.2;
	}
}
