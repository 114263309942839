dc-input {
	position: relative;
	display: block;

	&.form-input {
		@apply mb-4;
	}

	input {
		@apply appearance-none border border-gray-600 block w-full py-2 px-3 text-gray-800 leading-tight;

		&[readonly] {
			@apply bg-gray-200;
		}

		&.invalid {
			@apply bg-red-200;
		}
	}

	.validation-result {
		@apply text-red-500 text-xs font-bold absolute w-full left-0 bg-white;
		top: 100%;
		line-height: 1.2;
	}
}
