body {
	@apply text-gray-800;
}

.form-label {
	@apply block text-gray-800 text-sm font-bold mb-1;
	// This makes it possible for empty labels not to collapse if one is needed for layout reasons
	&:empty {
		&:before {
			content: "\a0";
		}
	}
}

.form-textarea {
	@apply shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight mb-4;
}

.btn {
	@apply inline-flex text-white items-center font-bold py-2 px-6 text-sm;

	&:disabled {
		@apply pointer-events-none opacity-50 text-white;
		background-color: #aabbbb;
		border-color: #aabbbb;
		// -webkit-filter: grayscale(1);
		// filter: grayscale(1);
	}

	&.btn-floating {
		@apply m-0;
	}

	&.btn-sm {
		@apply py-1 px-2 mx-0 my-1;
	}

	&.btn-xs {
		@apply py-1 px-1 mx-0 my-1 text-xs font-normal;
	}

	&:focus {
		@apply outline-none;
	}
}

.btn-primary {
	@apply border-2 bg-brand-primary border-brand-primary rounded-md;

	&:hover {
		@apply border-brand-primary bg-transparent text-brand-primary;
	}
}

.btn-secondary {
	@apply text-gray-500 border-0 bg-white;

	&:hover {
		@apply text-brand-secondary;
	}
}

.btn-tertiary {
	@apply text-brand-primary border-2 border-brand-primary rounded-md bg-white mb-0;

	&:hover {
		@apply text-white border-brand-secondary;
		background-color: $brand-secondary;
	}
}

.select-arrow {
	@apply bg-center bg-no-repeat;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>');
	background-size: 1rem 1rem;
}

.fr-select {
	@apply relative;

	&:after {
		content: "";
		bottom: 1.7rem;
		@apply opacity-50 pointer-events-none absolute right-0 flex items-center h-4 w-8;
	}
}

.btn-add-remove {
	@apply select-none rounded-full w-6 h-6 font-bold text-xl inline-flex items-center justify-center text-white;
}

.btn-toggle {
	@apply border border-gray-600 text-center font-semibold py-2 px-6 text-sm rounded-md cursor-pointer;
	min-height: 2.5rem;

	&.selected {
		@apply border-brand-primary-dark text-brand-primary-dark bg-brand-primary text-white;
	}

	&.disabled {
		@apply bg-gray-300 cursor-default;
	}
}

.btn-radio {
	@apply border border-gray-600 text-center font-semibold py-2 px-4 text-sm rounded-md cursor-pointer flex items-center;
	min-height: 2.5rem;

	&.selected {
		@apply border-brand-primary-light text-brand-primary;
	}

	&.disabled {
		@apply bg-gray-300 cursor-default;
	}

	&.tall {
		@apply h-16;
	}
}
