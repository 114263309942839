// https://projects.lukehaas.me/css-loaders/

@import "../../../../assets/css/variables";

$size: 10em;
$width: 1em;
$progress-color: lighten($brand-secondary, 33%);
$background-color: $brand-secondary;
$step: 1;
$loops: round(100 / $step);
$increment: calc(360 / $loops);
$half: round($loops / 2);

.loader-overlay {
	@apply fixed inset-0;
	z-index: 100;
	background: rgba(255, 255, 255, 0.2);
}

.loader-background {
	@apply rounded-full shadow-2xl absolute bg-white;
	font-size: 10px;
	width: #{$size + $width * 2};
	height: #{$size + $width * 2};
	top: 50%;
	left: 50%;
	margin: #{- calc($size/2) - $width} 0 0 #{- calc($size/2) - $width};
}

.loader,
.loader:after {
	@apply rounded-full;
	width: $size;
	height: $size;
}
.loader {
	@apply absolute;
	top: 50%;
	left: 50%;
	margin: #{- calc($size/2)} 0 0 #{- calc($size/2)};
	font-size: 10px;
	text-indent: -9999em;
	border-top: $width solid $background-color;
	border-right: $width solid $background-color;
	border-bottom: $width solid $background-color;
	border-left: $width solid $progress-color;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.loader-with-progress {
	@apply absolute inline-block;
	font-size: 10px;
	top: 50%;
	left: 50%;
	margin: #{- calc($size/2)} 0 0 #{- calc($size/2)};
	background-color: $background-color;
	border-radius: 50%;
	height: $size;
	width: $size;

	&:before {
		@apply items-center bg-white flex absolute;
		border-radius: 50%;
		content: "\25C0";
		color: $progress-color;
		left: $width;
		right: $width;
		top: $width;
		bottom: $width;
		transition: transform 0.2s ease;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load8 1.1s infinite linear;
		animation: load8 1.1s infinite linear;
	}

	&:after {
		@apply inline-block w-full h-full;
		background-color: $background-color;
		border-radius: 50%;
		content: "";
	}
}

@for $i from 0 through $loops {
	.loader-with-progress[data-progress="#{$i * $step}"]:after {
		@if ($i < $half) {
			$next-deg: 90deg + $increment * $i;
			background-image: linear-gradient(90deg, $background-color 50%, transparent 50%, transparent), linear-gradient($next-deg, $progress-color 50%, $background-color 50%, $background-color);
		} @else {
			$next-deg: -90deg + $increment * ($i - $half);
			background-image: linear-gradient($next-deg, $progress-color 50%, transparent 50%, transparent), linear-gradient(270deg, $progress-color 50%, $background-color 50%, $background-color);
		}
	}
}

.progress {
	@apply font-bold text-brand-secondary text-2xl flex items-center justify-center absolute;
	top: 50%;
	left: 50%;
	width: $size;
	height: $size;
	margin: #{- calc($size/2)} 0 0 #{- calc($size/2)};
	transform: translateX(4px);

	span {
		@apply text-lg font-normal;
	}
}
