// Colors
//
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-error: #d9534f;

$body-bg: #fff;

//Shades
//
$gray-darkest: #212121;
$gray-dark: #373a3c;
$gray: #55595c;
$gray-light: #818a91;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;

//Palette
//
$brand-primary: #506363;
$brand-primary-light: #677f7f;
$brand-primary-dark: #384646;
$brand-secondary: #6ce3b6;
$brand-secondary-light: #a7eed3;
$brand-secondary-dark: #41dba1;

$text-color: #232323;

//Utility
//
$brand-inverse: #222222;

$common-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px !default;
