dc-step-progressbar {
	@apply w-full;
	counter-reset: step;

	li {
		@apply list-none float-left relative text-center uppercase text-gray-400;

		&:before {
			@apply flex items-center justify-center w-8 h-8 leading-6 border border-gray-400 text-center mx-auto mt-0 mb-3 rounded-full bg-white;
			padding-top: 1px;
			content: counter(step);
			counter-increment: step;
		}

		&:after {
			@apply absolute w-full bg-gray-400;
			height: 2px;
			content: "";
			top: 15px;
			left: -50%;
			z-index: -1;
		}

		svg {
			@apply absolute w-4 h-4;
			top: 22px;
			left: 52%;
		}
	}

	li {
		&:first-child {
			&:after {
				content: none;
			}
		}
	}

	li {
		&.current {
			@apply font-bold text-brand-primary;

			&:before {
				@apply border-brand-primary text-brand-primary border-2;
			}
		}
	}

	li {
		&.active {
			@apply font-semibold text-brand-primary cursor-pointer;
			span {
				@apply text-brand-primary;
			}

			&:before {
				@apply border-brand-primary;
			}
		}

		li {
			&.active {
				+ li {
					&:after {
						@apply bg-brand-primary;
					}
				}
			}
		}
	}
}
