dc-multiselect {
	@apply relative;

	.contentbox {
		@apply border border-gray-600 px-1 flex flex-wrap items-center;
		min-height: 2.375rem;

		&:not(.readonly) {
			&:hover {
				@apply cursor-pointer;
			}
		}

		.selectedItem {
			@apply bg-gray-200 rounded-md px-1 flex items-center mr-1;

			font-size: 1rem;

			&.xxs {
				font-size: 0.8rem;
			}

			.selectedItemLabel {
				padding: 1px 2px 1px 2px;
			}

			.deleteSelectedItem {
				@apply pl-1 rounded-sm;

				padding: 0px 2px;

				&:hover {
					@apply bg-red-300;
				}
			}
		}
	}

	.selectbox {
		@apply border mt-1 overflow-auto absolute bg-white;

		min-width: 100%;
		z-index: 100;
		max-height: 300px;
	}

	.validation-result {
		@apply text-red-500 text-xs font-bold absolute w-full bg-white;
		line-height: 1.2;
	}
}
