.compliance-upload-modal {
	width: 60%;
	top: 10%;
	max-height: 80%;

	&.ioss-compliance {
		max-height: 40%;
	}

	@media screen and (max-width: 768px) {
		width: 90%;
		top: 5%;
	}
}
