@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,900");

@layer hover {
	.color-brand-primary {
		color: $brand-primary;
	}

	.color-brand-secondary {
		color: $brand-secondary;
	}
}
