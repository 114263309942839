dc-table {
	.dc-select {
		div {
			overflow: hidden;
		}
	}
	.dc-table {
		td {
			&.actions {
				overflow: visible;

				.dc-dropdown {
					@apply my-1 py-2;

					.select-button {
						@apply my-0 py-0;

						&:disabled {
							@apply cursor-default;
						}
					}
				}

				div.item {
					text-align: left;
				}
			}
		}
	}
}

.proof-batch-upload-modal {
	.dc-modal {
		max-width: 95%;
		&.default {
			width: 900px;
		}
	}
}
