@import "../../../../assets/css/variables";

dc-datepicker {
	position: relative;
	display: block;

	&.form-input {
		@apply mb-4;
	}

	input {
		@apply appearance-none border border-gray-600 block w-full px-3 text-gray-800 leading-tight;
		padding-top: 7px;
		padding-bottom: 6px;

		&[readonly] {
			@apply bg-gray-200;
		}

		&.invalid {
			@apply bg-red-200;
		}
		&::-webkit-calendar-picker-indicator {
			color: $brand-primary !important;
		}
	}
	.validation-result {
		@apply text-red-500 text-xs font-bold w-full left-0 bg-white p-1 mb-2;
		line-height: 1.2;
	}
}
