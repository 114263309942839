dc-fileupload {
	position: relative;
	display: block;

	&.form-input {
		@apply mb-4;
	}

	input {
		@apply appearance-none border border-gray-600 block w-full pl-1 pr-2 text-gray-800 leading-tight;
		padding-top: 2px;
		padding-bottom: 2px;

		&[readonly] {
			@apply bg-gray-200;
		}

		&.invalid {
			@apply bg-red-200;
		}

		&::file-selector-button {
			@apply inline-flex border-0 font-semibold text-sm bg-brand-primary text-white items-center px-6 rounded-md cursor-pointer;
			padding-top: 7px;
			padding-bottom: 7px;

			&:hover {
				@apply bg-brand-primary-dark;
			}
		}
	}

	.validation-result {
		@apply text-red-500 text-xs font-bold absolute w-full left-0 bg-white;
		top: 100%;
		line-height: 1.2;
	}
}
